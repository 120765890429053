import React from "react"
import { graphql } from "gatsby"

import BlogBlurb from "../components/blog-blurb"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { rhythm } from "../utils/typography"

class TagPageTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { tag } = pageContext
    const siteTitle = data.site.siteMetadata.title
    const { edges, totalCount } = data.allMarkdownRemark
    const tagHeader = `${totalCount} ${totalCount !== 1 ? "posts" : "post"} about ${tag}`
    const languageCounts = {}
    const blogBlurbs = edges.map(({ node }) => {
        languageCounts[node.frontmatter.lang] = (languageCounts[node.frontmatter.lang] || 0) + 1
        return <BlogBlurb key={node.fields.slug} {...node} />
    })

    let lang = `en`
    let highestCount = 0
    Object.keys(languageCounts).forEach((key) => {
      if (languageCounts[key] > highestCount) {
        lang = key
        highestCount = languageCounts[key]
      }
    })

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={`Wilson Mitchell's posts about ${tag}`}
          description={`Wilson Mitchell's posts about ${tag}`}
          keywords={[tag]}
	  lang={lang}
        />
        <h2 style={{ marginTop: rhythm(1.0) }}>{tagHeader}</h2>
        {blogBlurbs}
      </Layout>
    )
  }
}

export default TagPageTemplate

export const pageQuery = graphql`
  query BlogPostByTag($tag: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          excerpt(pruneLength: 160)
          html
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
	    tags
	    lang
          }
        }
      }
    }
  }
`
