import React from "react"
import { Link } from "gatsby"

import { scale, rhythm } from "../utils/typography"
import "../tags.css"

function BlogBlurb(props) {
  const title = props.frontmatter.title || props.fields.slug
  const tagLinks = props.frontmatter.tags.map((tag) => {
    return (
      <li key={tag} className={`tags-item`}>
        <Link to={`/blog/tags/${tag}`}>
          {tag}
        </Link>
      </li>
    )
  })
  return (
    <div key={props.fields.slug}>
      <h3
        style={{
          marginTop: rhythm(0.5),
          marginBottom: rhythm(0),
        }}
      >
        <Link style={{ boxShadow: `none` }} to={props.fields.slug}>
          {title}
        </Link>
      </h3>
      <small style={{
        display: `block`,
      }}>{props.frontmatter.date}</small>
      <small style={{
        display: `block`,
        marginBottom: rhythm(0.25),
      }}>
        Tags:{` `}
        <ul className={`tags-list`}>
          {tagLinks}
        </ul>
      </small>
      <p
        dangerouslySetInnerHTML={{
          __html: props.frontmatter.description || props.excerpt,
        }}
      />
    </div>
  )
}

export default BlogBlurb
